#background {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index:-1;
}

.landing-container{

    background: url('../../../public/images/originals/landing-img.jpg') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    object-fit: contain;
}

.landing-container > h1 {
    color: white;
    font-size: 100px;
    margin-top: -100px;
    font-family: 'PT Sans', sans-serif;
}

.landing-container > p {
    color: white;
    font-size: 32px;
    margin-top: 8px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.landing-btn{
    margin-top: 32px;
    text-align: center;
}

.landing-btn .btn{
    margin: 6px;
    font-family: 'PT Sans', sans-serif;
}

@media screen and (max-width: 960px) {
    .landing-container > h1 {
        font-size: 70px;
        margin-top: -150px;
    }
}

@media screen and (max-width: 768px) {
    .landing-container > h1 {
        font-size: 50px;
        margin-top: -100px;
    }

    .landing-container > p {
        font-size: 30px;
    }
    .btn-mobile{
        display: block;
        text-decoration: none;
        margin: 0px;
    }

    .btn{
        width: 60%;
    }
}