.navbar {
    background-color: #1C1A1A;
    height: 80px;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
}



.navbar>h1 {
    color: white;
    font-size: 35px;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 80px;
    max-width: 1500px;
}

.navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
}

.fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
}

.nav-menu {
    display: grid;
    justify-self: start;
    margin-left: 50px;
    grid-template-columns: repeat(5, auto);
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: left;

}

.nav-item {
    height: 80px;
}

.nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    cursor: pointer;
}

.nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 1020px) {
    .navbar>h1 {
        color: white;
        font-size: 30px;
    }
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
        width: 100%;
    }
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;

    }
    .nav-menu.active {
        background: #242222;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;

    }
    .nav-links {
        vertical-align: middle;
        text-align: center;
        padding: 2rem;
        width: 100%;
        justify-content: center;
    }
    .nav-links:hover {
        background-color: rgb(148, 148, 148);
        color: #242424;
        border-radius: 1px;
    }
    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }
    .nav-item {
        height: 20%;
    }
    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
    .fa-times {
        color: #fff;
        font-size: 2rem;
    }
    /*delete below*/
    .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: #fff;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
    }
    .nav-links-mobile:hover {
        background: rgb(172, 172, 172);
        color: #0f0f0f;
        transition: 250ms;
    }
    #logo{
        transform: translate(0, -110%);
    }
}

@media screen and (max-width: 500px) {
    .navbar>h1 {
        visibility: hidden;
    }
}