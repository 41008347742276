*{
   font-family:  'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.footer-container{
   background-color: #242424;
   padding: 2rem 0 1rem 0;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.footer-contact{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;
   margin-bottom: 24px;
   padding: 24px;
   color: white;
}

.footer-contact > p{
   font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.footer-link{
   cursor: pointer;
}

.footer-contact-heading{
   margin-bottom: 24px;
   font-size: 24px;
}

.footer-input{
   padding: 8px 20px;
   border-radius: 2px;
   margin-right: 10px;
   outline: none;
   border: none;
   font-size: 18px;
   margin-bottom: 15px;
   border: 1px solid #fff;
}

/*Links*/

.footer-link-wrapper{
   display: flex;
}

.footer-link-item{
   display: flex;
   flex-direction: column;
   align-items: left;
   margin: 16px;
   width: 200px;
   box-sizing: border-box;
}

.footer-link-item h2{
   font-family: 'PT Sans', sans-serif;
   font-weight: 500;
   font-size: 1.3rem;
   color: #fff;
   margin-bottom: 18px;
}

#design-card{
   padding-left: 50px;
}

.footer-link-item a{
   color: #fff;
   text-decoration: none;
   margin-bottom: 10px;
   text-align: left;
   font-size: 15px;
}

.footer-link-item a:hover{
   color: #e9e9e9da;
   transition: 0.3s ease-out;
}

/*Sdelete below?*/
.footer-email-form h2{
   margin-bottom: 32px;
}

.footer-input::placeholder{
   color: #b1b1b1;
}

/*Social icons*/

.social-icons-link{
   color: #fff;
   font-size: 24px;
}

.social-media{
   max-width: 1200px;
   width: 100%;
}

.social-media-wrap{
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 90%;
   max-width: 1000px;
   margin: 25px auto 0 auto;
}

.social-icons{
   
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 200px;
}

.social-logo{
   color: #fff;
   justify-self: start;
   margin-left: 20px;
   cursor: pointer;
   text-decoration: none;
   font-size: 2rem;
   display: flex;
   align-items: center;
   margin-bottom: 16px;
}

.website-rights{
   color: #fff;
   margin-bottom: 16px;
   text-align: right;
}

@media screen and (max-width: 1024px){
   .footer-link-item{
      width: 150px;
   }
   
}

@media screen and (max-width: 820px){
   .footer-link{
      padding-top: 0.5rem;
      display: flex;
      flex-direction: column;
   }
   .footer-input{
      width: 100%;
   }
   .btn{
      width: 100%;
   }
   .footer-link-wrapper{
      flex-direction: column;
   }
   .social-media-wrap{
      flex-direction: column;
      margin-top: 30px;
   }
   .footer-link-item{
      align-items: center;
   }
   .footer-link-item h2{
      text-align: center;
   }
   .social-icons{
      padding-top: 10px;
      padding-bottom: 20px;
   }
   #design-card{
      padding-left: 0px;
   }
}